import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import useHover from "utils/useHover"
import { gsap } from "gsap/all"
import { NavigationBar } from "./NavigationBar"

export const TopBar: React.FC = (props) => {
  const { primaryItems, secondaryItems, sticky } = props

  const [hoverRef, isHovered] = useHover<HTMLDivElement>()
  const hoveredRef = useRef()
  const firstColorRef = useRef()
  const secondColorRef = useRef()

  const COLOR_COMBOS = [
    { first_color: "#EC3E3F", second_color: "#FEC430" },
    { first_color: "#FEC430", second_color: "#EC3E3F" },
    { first_color: "#0029C8", second_color: "#FEC430" },
    { first_color: "#FEC430", second_color: "#0029C8" },
    { first_color: "#0029C8", second_color: "#EC3E3F" },
    { first_color: "#EC3E3F", second_color: "#0029C8" },
    { first_color: "#EC3E3F", second_color: "#FEC430" },
  ]
  const [colorSet, setColorSet] = useState(COLOR_COMBOS[0])
  const [colorInterval, setColorInterval] = useState(null)
  const [isHeaderActive, setIsHeaderActive] = useState(false)

  const toggleHeaderActive = (state = false) => {
    setIsHeaderActive(state)
  }

  // make hoveredRef always have the current hovered status to be accessed from callbacks
  hoveredRef.current = isHovered

  useEffect(() => {
    if (isHovered) {
      if (!colorInterval) {
        const logoTimeline = gsap.timeline({
          onComplete: () => {
            hoveredRef.current ? logoTimeline.restart() : null
          },
        })
        COLOR_COMBOS.map((combo, index) => {
          logoTimeline
            .set(firstColorRef.current, {
              fill: combo.first_color,
              delay: index === 0 ? 0 : 0.7,
            })
            .set(
              secondColorRef.current,
              {
                fill: combo.second_color,
              },
              "<"
            )
        })
        setColorInterval(logoTimeline)
      } else {
        if (!colorInterval.isActive()) {
          colorInterval.restart()
        }
      }
    }
  }, [isHovered])

  useEffect(() => {
    gsap.to(firstColorRef.current, {
      duration: 0.5,
      fill: colorSet.first_color,
    })
    gsap.to(secondColorRef.current, {
      duration: 0.5,
      fill: colorSet.second_color,
    })

    gsap.to(hoverRef.current, { duration: 0.5, opacity: 1, delay: 0.1 })
  }, [])

  return (
    <TopBarWrapper
      className="absolute flex flex-col w-full"
      {...props}
      isMobile={isMobile}
      isActive={isHeaderActive}
    >
      <NavigationBar
        leftItems={primaryItems}
        rightItems={secondaryItems}
        sticky={sticky}
        toggleHeader={toggleHeaderActive}
      />
      <TopBarLink style={{ boxShadow: "none" }} href="/">
        <SVGLogo
          className="topbar__logo--svg ml:4 md:ml-6"
          width="179"
          height="101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={hoverRef}
          {...props}
        >
          <g id="se-logos">
            <path
              className="se-1"
              ref={firstColorRef}
              d="M178.801 29.607c0-17.677-14.229-28.171-34.64-28.171-30.039 0-53.47 27.165-53.47 57.345 0 19.834 9.632 41.68 31.622 41.68 14.659 0 28.459-11.93 28.459-27.88 0-15.236-18.971-10.638-22.567-25.583-.577-2.442 2.586-3.448 3.449-1.006 2.299 8.91 13.8 12.216 19.978 12.216 13.37 0 27.169-11.643 27.169-28.6Z"
              fill={colorSet?.first_color}
            />
            <path
              className="se-1"
              ref={secondColorRef}
              d="M95.724 21.99C95.724 6.898 82.5 0 68.27 0 44.701 0 25.44 18.684 25.44 42.83c0 4.885 2.012 11.93 2.585 14.372.43 1.579-.143 1.436-1.579.863-1.15-.43-3.306-.72-5.748-.72C6.755 57.345 0 69.275 0 78.618c0 15.092 12.937 22.133 27.596 22.133 23.717 0 42.831-19.404 42.831-42.83 0-4.884-.43-9.486-2.299-13.942-.577-1.293-.143-1.87.863-1.579 4.456 1.293 10.347 1.87 14.95-.43 7.184-3.596 11.783-12.506 11.783-19.98Z"
              fill={colorSet?.second_color}
            />
          </g>
        </SVGLogo>
      </TopBarLink>
    </TopBarWrapper>
  )
}

const TopBarWrapper = styled.div`
  z-index: 99;
  background-color: #f9f9f9;
  max-height: auto;
  transition: background-color 0.2s ease-out;
  padding: 0px;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    background: #f9f9f9;
    width: 85px;
    height: 100%;
    z-index: 4;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background-color: #f9f9f9;
  }

  @media (min-width: 768px) {
    padding: 15px 0px;
    background-color: ${(props) =>
      !props.transparentHeader || props.sticky || props.isActive
        ? "#F9F9F9"
        : "transparent"};
  }
`

const TopBarLink = styled.a``

const SVGLogo = styled.svg`
  position: absolute;
  cursor: pointer;
  transform-origin: 0px 0px;
  transform: scale(${(props) => (props.logoScale ? props.logoScale : 0.36)});
  left: 0px;
  top: ${(props) => `${props.logoPosition ? props.logoPosition : 0}px`};
  -webkit-transition: transform 200ms, 100ms top ease 0s, opacity 200ms;
  -moz-transition: transform 200ms, 100ms top ease 0s, opacity 200ms;
  transition: transform 200ms, 100ms top ease 0s, opacity 200ms;
  z-index: 5;
  margin-left: 10px;
  margin-top: 5px;
  opacity: 0;

  @media (min-width: 768px) {
    margin-left: 25px;
    margin-top: 20px;
  }
`
