import React, { useState } from "react"
import styled from "styled-components"
import LazyImage from "./LazyImage"
import { StoryProps } from "../shared-types"
import { Link } from "gatsby"
import Flickity from "react-flickity-component"
import { getLinkTarget, handleize } from "utils/helpers"

const MasonryProductTags: React.FC = (props) => {
  const { tags, colorMode } = props

  const tagList = Array.isArray(tags) ? tags : tags.split(",")

  return tagList.length > 0 ? (
    <MasonryBlockTags {...props}>
      {tagList.map((tag, index) => (
        <MasonryBlockTag
          className={`border border-solid rounded-xl px-2 mx-1 font-fp-book py-0.5 uppercase border-${colorMode} text-${colorMode}`}
          key={`content-slider__tags--tag-${index}`}
        >
          {tag}
        </MasonryBlockTag>
      ))}
    </MasonryBlockTags>
  ) : null
}

export const ProductCards: React.FC<StoryProps> = (props) => {
  const {
    blok: { _uid, section_id, products },
  } = props

  const [layoutMode, setLayoutMode] = useState("grid") // [grid, carousel]

  const sectionName = section_id ? handleize(section_id) : _uid

  const FLICKITY_OPTIONS = {
    initialIndex: 1,
    cellAlign: "left",
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
  }

  const switchLayoutMode = (event) => {
    const newLayoutMode = event.currentTarget.getAttribute("data-mode")
    if (newLayoutMode && newLayoutMode !== layoutMode) {
      setLayoutMode(newLayoutMode)
    }
  }

  const getBlockImage = (product) => {
    return product?.image?.filename
      ? product?.image?.filename
      : product?.cta_link?.story?.content?.main_image?.filename
  }

  return (
    <MasonryWrapper
      className={"product__cards"}
      name={sectionName}
      id={sectionName}
    >
      <MasonrySwitcher className="md:hidden pt-3 ml-auto flex flex-row">
        <MasonrySwitcherButton
          data-mode="carousel"
          value="Carousel"
          onClick={(e) => switchLayoutMode(e)}
        >
          <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke={`${layoutMode === "carousel" ? "#000000" : "#A3A3A3"}`}
              d="M.5.5h31v31H.5z"
            />
            <path
              stroke={`${layoutMode === "carousel" ? "#000000" : "#A3A3A3"}`}
              d="M.5.5h16v31H.5z"
            />
          </svg>
        </MasonrySwitcherButton>
        <MasonrySwitcherButton
          data-mode="grid"
          value="Grid"
          onClick={(e) => switchLayoutMode(e)}
        >
          <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke={`${layoutMode === "grid" ? "#000000" : "#A3A3A3"}`}
              d="M.5.5h31v31H.5zM16.5 0v31M0 15.5h32"
            />
          </svg>
        </MasonrySwitcherButton>
      </MasonrySwitcher>
      <MasonryGrid
        className={`grid grid-cols-16 ${
          layoutMode === "carousel" ? "hidden" : ""
        }`}
      >
        {(products || []).map((product, index) => {
          return (
            <MasonryBlock
              className={`
            ${
              layoutMode === "grid"
                ? `col-span-8 md:${
                    product.col_span ? product.col_span : "col-span-5"
                  }`
                : `col-span-12 md:${
                    product.col_span ? product.col_span : "col-span-5"
                  }`
            } relative`}
              key={`mblock--${index}`}
            >
              <Link
                to={`${getLinkTarget(
                  product?.cta_link
                )}?section=${sectionName}`}
              >
                <LazyImage
                  className={`absolute object-cover object-center w-full h-full`}
                  src={getBlockImage(product)}
                  alt={product.title}
                  resize={"968x0"}
                />
                <MasonryProductTags
                  className={`flex absolute top-0 left-0 mt-3 ml-3`}
                  tags={product.tags}
                  colorMode={product.style}
                />
                <MasonryButton
                  role="button"
                  className="absolute bg-yellow-regular top-3 right-3 uppercase rounded-full px-5 py-0.5 font-fp-book"
                  href={product?.cta_link?.url}
                >
                  {product?.cta_label}
                </MasonryButton>
                <MasonryHeading
                  className={`font-fp-regular mt-auto ml-3 mb-4 text-${product.style}`}
                >
                  {product.title}
                </MasonryHeading>
              </Link>
            </MasonryBlock>
          )
        })}
      </MasonryGrid>
      <Flickity
        className={`carousel ${layoutMode === "grid" ? "hidden" : ""}`} // default ''
        elementType={"div"} // default 'div'
        options={FLICKITY_OPTIONS} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {(products || []).map((product, index) => (
          <MasonryBlock key={`cblock--${index}`} isCarousel={true}>
            <Link
              to={`${getLinkTarget(product?.cta_link)}?section=${sectionName}`}
            >
              <LazyImage
                className={`absolute object-cover object-center w-full h-full --`}
                src={getBlockImage(product)}
                alt={product.title}
                resize={"0x360"}
              />
              <MasonryProductTags
                className={`font-fp-book flex absolute top-0 left-0 mt-3 ml-3 border border-solid rounded-xl px-2 text-xs py-0.5 uppercase border-${product.style} text-${product.style}`}
                tags={product.tags}
                colorMode={product.style}
              />
              <MasonryButton
                role="button"
                className="absolute bg-yellow-regular top-3 right-3 uppercase rounded-full px-5 py-0.5 font-fp-book text-xs"
                href={product?.cta_link?.url}
              >
                {product?.cta_label}
              </MasonryButton>
              <MasonryHeading
                className={`font-fp-regular mt-auto ml-3 mb-4 text-${product.style}`}
              >
                {product.title}
              </MasonryHeading>
            </Link>
          </MasonryBlock>
        ))}
      </Flickity>
    </MasonryWrapper>
  )
}

const MasonryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 16px);
  margin: 0 auto 60px auto;

  .slick-slide {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    max-width: calc(100vw - 50px);
    width: 100%;
  }
`

const MasonryGrid = styled.div`
  grid-gap: 8px;

  @media (min-width: 768px) {
    padding: 30px 0px;
  }
`

const MasonrySwitcher = styled.div`
  padding-bottom: 8px;
`

const MasonrySwitcherButton = styled.button`
  &:last-child {
    margin-left: 11px;
  }
`

const MasonryBlockTags = styled.div``

const MasonryBlockTag = styled.div`
  font-size: 0.5rem;
  line-height: 0.75rem;

  @media (min-width: 768px) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

const MasonryBlock = styled.div`
  position: relative;
  border-radius: 5px;
  min-height: 272px;
  overflow: hidden;

  ${(props) =>
    props.isCarousel &&
    `
    display: grid;
    min-height: 344px;
    min-width: 60vw;
    margin-right: 8px;
  `}

  a {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: none;

    img {
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }

  @media (min-width: 768px) {
    min-height: 700px;
  }
`

const MasonryHeading = styled.h3`
  font-size: 1rem;
  z-index: 1;

  @media (min-width: 768px) {
    font-size: 2.125rem;
  }
`

const MasonryButton = styled.div`
  font-size: 0.5rem;
  line-height: 0.75rem;

  @media (min-width: 768px) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`
