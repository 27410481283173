import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { TopBar } from "./TopBar"
import { gsap, ScrollTrigger } from "gsap/all"

export const Header: React.FC = (props) => {
  gsap.registerPlugin(ScrollTrigger)

  const { primaryMenu, secondaryMenu, transparentHeader } = props

  const [isSticky, setIsSticky] = useState(false)
  const HeaderRef = useRef()

  useEffect(() => {
    ScrollTrigger.create({
      trigger: HeaderRef.current,
      start: "top -80",
      end: 99999,
      toggleClass: {
        className: "navbar--fixed",
        targets: "body",
      },
      onEnter: () => setIsSticky(true),
      onUpdate: (self) => {
        self.progress === 0 ? setIsSticky(false) : null
      },
    })
  }, [])

  return (
    <>
      <span ref={HeaderRef}></span>
      <HeaderWrapper>
        <TopBar
          primaryItems={primaryMenu}
          secondaryItems={secondaryMenu}
          sticky={isSticky}
          transparentHeader={transparentHeader}
        />
      </HeaderWrapper>
    </>
  )
}

const HeaderWrapper = styled.header`
  position: sticky;
  z-index: 2;
  width: 100%;
  top: 0px;

  .flyout-open & {
    z-index: 999;
  }
`
