import React, { useState } from "react"
import styled from "styled-components"
import { GET_FEATURED_PRODUCTS } from "../shared-queries"
import { useQuery } from "@apollo/client"
import LazyImage from "./LazyImage"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

export const Sidebar: React.FC = (props) => {
  const { mode = "page" } = props // [flyout - page]

  const [featuredPosts, setFeaturedPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const location = useLocation()
  const excludedPost = location.pathname
  const { error, data } = useQuery(GET_FEATURED_PRODUCTS, {
    variables: {
      slug: excludedPost ? excludedPost.replace(/^\/|\/$/g, "") : "",
    },
    onCompleted: () => {
      if (error) return null
      const {
        ProductItems: { items },
      } = data
      const itemsList = [...items]
      const shuffledItems = itemsList.sort(() => Math.random() - 0.5)
      setFeaturedPosts([...shuffledItems])
      setIsLoading(false)
    },
    ssr: false,
  })

  const getPostExtract = (post) => {
    const postDescription =
      post.content?.description?.content[0]?.content[0]?.text
    const postExcerpt = post.content?.preview_excerpt?.content
    return postExcerpt
      ? postExcerpt
      : postDescription
      ? postDescription.split(" ").splice(0, 15).join(" ")
      : ""
  }

  return (
    <SidebarWrapper className="bg-gray-light">
      <SidebarSticky>
        {featuredPosts.map((post, index) => (
          <SidebarPost
            className={`${index > 0 ? "pt-7" : ""}`}
            key={`sidebar__post--${index} flex flex-column`}
          >
            <Link to={`/${post.full_slug}`}>
              <SidebarPostImage>
                {post?.content?.preview_image?.filename ? (
                  <LazyImage
                    className={``}
                    src={post?.content?.preview_image?.filename}
                    alt={post?.name}
                  />
                ) : (
                  <LazyImage
                    className={``}
                    src={post?.content?.main_image?.filename}
                    alt={post?.name}
                  />
                )}
              </SidebarPostImage>
              <SidebarPostTitle>{post?.name}</SidebarPostTitle>
              <SidebarPostDescription
                dangerouslySetInnerHTML={{ __html: getPostExtract(post) }}
              />
            </Link>
          </SidebarPost>
        ))}
      </SidebarSticky>
    </SidebarWrapper>
  )
}

const SidebarWrapper = styled.aside`
  display: none;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    max-width: 218px;
  }
`

const SidebarSticky = styled.div`
  position: sticky;
  top: 0px;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
`

const SidebarPost = styled.div`
  img {
    transition: all 0.1s ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.03);
    }
  }
`

const SidebarPostTitle = styled.h3`
  font-family: "Favorit Pro Light";
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
`

const SidebarPostDescription = styled.div`
  font-family: "Favorit Pro Light";
  position: relative;
  font-size: 1rem;
  line-height: 1.375rem;
`

const SidebarPostImage = styled.div`
  position: relative;
  height: 248px;
  width: auto;
  overflow: hidden;
`
