import React from "react"
import styled from "styled-components"

export const Paragraph: React.FC = (props) => {
  const {
    blok: {
      pretitle,
      title,
      content,
      padding_top,
      padding_bottom,
      margin_top,
      margin_bottom,
      padding_top_mobile,
      padding_bottom_mobile,
      margin_top_mobile,
      margin_bottom_mobile,
    },
  } = props

  return (
    <ParagraphMain
      paddingTop={padding_top}
      paddingBottom={padding_bottom}
      marginTop={margin_top}
      marginBottom={margin_bottom}
      paddingTopMobile={padding_top_mobile}
      paddingBottomMobile={padding_bottom_mobile}
      marginTopMobile={margin_top_mobile}
      marginBottomMobile={margin_bottom_mobile}
    >
      {pretitle && <PreTitle>{pretitle}</PreTitle>}
      {title && <ParagraphTitle>{title}</ParagraphTitle>}
      {content && content?.content && (
        <ParagraphWrapper
          dangerouslySetInnerHTML={{ __html: content?.content }}
        ></ParagraphWrapper>
      )}
    </ParagraphMain>
  )
}

const ParagraphMain = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.marginTopMobile ? `margin-top: ${props.marginTopMobile};` : null}
  ${(props) =>
    props.marginBottomMobile
      ? `margin-bottom: ${props.marginBottomMobile};`
      : null}
  ${(props) =>
    props.paddingTopMobile ? `padding-top: ${props.paddingTopMobile}px;` : null}
  ${(props) =>
    props.paddingBottomMobile
      ? `padding-bottom: ${props.paddingBottomMobile}px;`
      : null}

  @media (min-width: 768px) {
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : null)}
    ${(props) =>
      props.marginBottom ? `margin-bottom: ${props.marginBottom};` : null}
    ${(props) =>
      props.paddingTop ? `padding-top: ${props.paddingTop}px;` : null}
    ${(props) =>
      props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : null}
  }
`

const PreTitle = styled.h1`
  font-family: "Favorit Pro Light";
  font-size: 16px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin-bottom: 4rem;
  }
`

const ParagraphTitle = styled.h2`
  font-family: "Favorit Pro Light";
  font-size: 42px;
  text-align: center;
  margin-bottom: 66px;

  @media (min-width: 768px) {
    font-size: 64px;
    margin-bottom: 0px;
  }
`

const ParagraphWrapper = styled.div`
  font-family: "Favorit Pro Light";
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
`
