import React, { useState, useEffect } from "react"
import { GET_PRODUCT_BY_SLUG } from "shared-queries"
import { useLocation } from "@reach/router"
import { useQuery } from "@apollo/client"
import { Link } from "gatsby"
import styled from "styled-components"
import { getLinkTarget } from "../utils/helpers"
import LazyImage from "./LazyImage"

export const NextPrevPost: React.FC = () => {
  const [currentPost, setCurrentPost] = useState(null)
  const [nextPost, setNextPost] = useState(null)

  const collections = ["products"]
  const location = useLocation()
  const excludedPost = location.pathname
  const hasNextPostFlag = collections.findIndex((slug) =>
    location.pathname.includes(slug)
  )

  const { error, data } = useQuery(GET_PRODUCT_BY_SLUG, {
    variables: {
      mainSlug: `${excludedPost.replace(/^\/|\/$/g, "")}`,
      secondarySlug: `${excludedPost.replace(/^\/|\/$/g, "")}`,
    },
    onCompleted: () => {
      if (error) return null
      setCurrentPost({
        slug: data?.ProductItem?.full_slug,
        ...data?.ProductItem?.content,
      })
      if (
        data?.ProductItems &&
        Array.isArray(data?.ProductItems?.items) &&
        data?.ProductItems?.items.length > 0
      ) {
        const nextProductItem = data?.ProductItems?.items.find(
          (productItem) => {
            return productItem.id > data?.ProductItem?.id
          }
        )

        // If there's no next post available we circle back to first element
        if (!nextProductItem) {
          setNextPost({
            slug: `products/${data?.ProductItems?.items[0]?.slug}`,
            ...data?.ProductItems?.items[0]?.content,
          })
        } else {
          setNextPost({
            slug: `products/${nextProductItem?.slug}`,
            ...nextProductItem?.content,
          })
        }
      }
    },
    skip: !excludedPost || hasNextPostFlag === -1,
    ssr: false,
  })

  return (
    <>
      {nextPost && (
        <ProductNextRow className="product-layout__next">
          <Link
            className={`product-layout__nextp--link`}
            to={
              currentPost.next_link && currentPost.next_link?.cached_url
                ? getLinkTarget(currentPost.next_link)
                : nextPost?.slug
            }
          >
            <ProductNextRowCol className={"product-layout__label"}>
              <ProductNextRowLabel>Next</ProductNextRowLabel>
            </ProductNextRowCol>
            <ProductNextRowCol className={"product-layout__cta"}>
              <ProductNextRowLabel className={`product-layout__nextp--title`}>
                {currentPost?.next_title
                  ? currentPost?.next_title
                  : nextPost?.name}
                <svg
                  width="53"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="m46.343.929 6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 0 1-1.414-1.414L49.586 9H0V7h49.586l-4.657-4.657A1 1 0 0 1 46.343.93Z"
                    fill="#000"
                  />
                </svg>
              </ProductNextRowLabel>
            </ProductNextRowCol>
            <ProductNextRowCol className={"product-layout__media"}>
              {currentPost?.next_image?.filename ? (
                <LazyImage
                  className={`product-layout__nextp--image`}
                  src={currentPost?.next_image?.filename}
                  alt={
                    currentPost?.next_image?.alt
                      ? currentPost?.next_image?.alt
                      : ""
                  }
                  resize={"320x263"}
                />
              ) : nextPost?.preview_image?.filename ? (
                <LazyImage
                  className={`product-layout__nextp--image`}
                  src={nextPost?.preview_image?.filename}
                  alt={
                    nextPost?.preview_image?.alt
                      ? nextPost?.preview_image?.alt
                      : nextPost?.name
                  }
                  resize={"320x263"}
                />
              ) : (
                <LazyImage
                  className={`product-layout__nextp--image`}
                  src={nextPost?.main_image?.filename}
                  alt={nextPost?.name}
                  resize={"320x263"}
                />
              )}
            </ProductNextRowCol>
          </Link>
        </ProductNextRow>
      )}
    </>
  )
}

const ProductNextRow = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  > a {
    display: flex;
    flex-direction: column;
    background-color: #fec430;
    padding: 20px 8px;
    height: max-content;
    margin-top: auto;
    grid-column: 1 / -1;
    box-shadow: none;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 38px 24px 30px 24px;
    }
  }

  &:hover {
    img {
      transform: scale(1.03);
    }
  }
`

const ProductNextRowCol = styled.div`
  width: auto;
  .product-layout__nextp--title {
    font-family: "Favorit Pro Light";
    font-size: 2.625rem;
    line-height: 3.125rem;
    box-shadow: none;

    @media (min-width: 768px) {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      font-size: 4rem;
      line-height: 4.25rem;
      margin: 0px;

      svg {
        margin-left: 34px;
      }
    }
  }
  .product-layout__nextp--image {
    position: relative;
    width: 100%;
    height: 263px;
    margin-left: auto;
    overflow: hidden;

    @media (min-width: 768px) {
      width: 320px;
    }

    img {
      transition: all 0.1s ease-in-out;
    }
  }

  &.product-layout__label {
    font-family: "Favorit Pro Light";
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    max-width: auto;

    @media (min-width: 768px) {
      max-width: 70px;
    }
  }

  &.product-layout__cta {
    margin-top: 12px;
    margin-bottom: 18px;

    @media (min-width: 768px) {
      flex-grow: 1;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  @media (min-width: 768px) {
    width: 33.33%;
  }
`

const ProductNextRowLabel = styled.div`
  @media (min-width: 768px) {
    margin-top: calc(4.25rem - 30px);
  }
`
