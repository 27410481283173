import React, { useRef, useState } from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import LazyImage from "./LazyImage"
import { Link } from "gatsby"
import gsap from "gsap/all"
import SplitText from "../utils/gsap/SplitText"
import { isInViewport } from "../utils/helpers"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { isMobile } from "react-device-detect"
import { useLocation } from "@reach/router"
import { useEffect } from "react"

export const ProductLayout: React.FC = (props) => {
  const { blok, enableBackAction } = props

  const descriptionRef = useRef(null)
  const productHeaderRef = useRef(null)
  const scrollTimeout = useRef(null)

  const [animationLoadedOnce, setAnimationLoadedOnce] = useState(false)
  const [backFixed, setBackFixed] = useState<string>("")
  const { search } = useLocation()

  gsap.registerPlugin(SplitText)

  const content =
    blok.body &&
    blok.body
      .filter((childBlok) => childBlok.visibility !== true)
      .map((childBlok) => (
        <DynamicComponent blok={childBlok} key={childBlok._uid} />
      ))

  const hasTitle =
    blok.name && blok.name.length ? (
      <ProductHeaderTitle className="text-left col-span-full">
        {blok.name}
      </ProductHeaderTitle>
    ) : null

  const productTags =
    blok.tags && blok.tags.length ? (
      <ProductHeaderTags className="col-span-full text-right">
        {(blok?.tags || []).map((tag) => (
          <ProductHeaderTag>{tag}</ProductHeaderTag>
        ))}
      </ProductHeaderTags>
    ) : (
      <ProductHeaderTags />
    )

  const animateText = () => {
    if (
      descriptionRef.current &&
      true === isInViewport(descriptionRef.current, 40) &&
      animationLoadedOnce !== true
    ) {
      const isMobileViewport = window.matchMedia("(max-width: 767px)")
      if (typeof document !== "undefined" && isMobileViewport.matches) {
        const copyTimeline = gsap.timeline()
        copyTimeline.set(descriptionRef.current, { opacity: 1 })
      } else {
        const innerSplit = new SplitText(descriptionRef.current, {
          type: "lines",
          linesClass: "split-child",
        })
        const outerSplit = new SplitText(descriptionRef.current, {
          linesClass: "split-parent",
        })

        const copyTimeline = gsap.timeline()
        copyTimeline.set(descriptionRef.current, { opacity: 1 })
        copyTimeline.from(innerSplit.lines, {
          duration: 0.8,
          yPercent: 100,
          ease: "power4",
          stagger: 0.1,
          delay: 0.3,
          onComplete: () => {
            // Making sure splitText animation is not going to break resize
            window.addEventListener("resize", function () {
              outerSplit.revert()
              innerSplit.revert()
            })
          },
        })
      }
      setAnimationLoadedOnce(true)
    }
  }

  useScrollPosition(() => {
    animateText()

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current)
    }

    if (window && window.document) {
      const upperLimits = document
        .querySelector(".product-layout__header")
        ?.getBoundingClientRect()
      const lowerLimits = document
        .querySelector(".product-layout__next")
        ?.getBoundingClientRect()

      if (isMobile) {
        if (upperLimits?.bottom < 109 && lowerLimits?.y > 86) {
          setBackFixed("fixed")
          scrollTimeout.current = setTimeout(() => {
            setBackFixed("fixed-top")
          }, 300)
        } else {
          setBackFixed("")
        }
      } else {
        if (upperLimits?.y < 65 && lowerLimits?.y > 118) {
          setBackFixed("fixed")
        } else {
          setBackFixed("")
        }
      }
    }
  }, [
    descriptionRef,
    animationLoadedOnce,
    productHeaderRef,
    setBackFixed,
    scrollTimeout,
  ])

  // Preload content on mobile cause its visible for most of the viewports
  useEffect(() => {
    setTimeout(animateText, 300)
  }, [])

  return (
    <>
      <SbEditable content={blok} key={blok._uid}>
        <ProductHeader className="grid-cols-16 px-2 md:px-0 product-layout__header">
          {enableBackAction ? (
            <ProductHeaderBack className="product-layout__header--bw">
              <ProductBackLink
                className="product-layout__header--back"
                isFixed={backFixed}
                to={`/${search ? search : ""}`}
              >
                Back
              </ProductBackLink>
            </ProductHeaderBack>
          ) : null}
          {hasTitle}
          {productTags}
        </ProductHeader>
        <ProductMainContent className="grid grid-cols-12 pb-20 md:pb-20 product-layout__main">
          {blok?.main_image?.filename && (
            <ProductMainContentImage className="col-span-full">
              <LazyImage
                className={``}
                src={blok?.main_image?.filename}
                alt={blok?.main_image?.alt}
                resize={"2048x1080"}
              />
            </ProductMainContentImage>
          )}
          {blok?.description ? (
            <ProductMainContentDescription
              ref={descriptionRef}
              className="col-span-full px-2 mt-10 md:col-start-5 md:col-end-13"
            >
              {blok?.description?.content[0]?.content[0]?.text}
            </ProductMainContentDescription>
          ) : null}
        </ProductMainContent>
        {content ? (
          <ProductStoryBlocks className="">{content}</ProductStoryBlocks>
        ) : null}
      </SbEditable>
    </>
  )
}

const ProductHeader = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2.625rem;
    line-height: 3.125rem;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 112px 600px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "back header tags";

    h1 {
      font-size: 4rem;
      line-height: 4.75rem;
    }
  }
`

const ProductHeaderBack = styled.div`
  position: relative;
  display: flex;
  font-family: "Favorit Pro Light";
  font-size: 20px;
  line-height: 24px;
  order: 2;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    grid-area: back;
    justify-content: flex-start;
    padding-left: 24px;
    padding-bottom: 32px;
  }
`

const ProductHeaderTitle = styled.h1`
  font-family: "Favorit Pro Light";
  font-size: 64px;
  line-height: 76px;
  order: 1;

  @media (min-width: 768px) {
    grid-area: header;
    margin-bottom: 16px;
  }
`

const ProductBackLink = styled(Link)`
  position: absolute;
  font-family: "Favorit Pro Light";
  font-size: 20px;
  box-shadow: none;
  text-transform: uppercase;
  z-index: 5;
  line-height: 24px;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 768px) {
    position: relative;
    margin-top: calc(4rem - 24px);
    top: 0px;
    transform: none;

    ${(props) =>
      props.isFixed === "fixed" &&
      `
      position: fixed;
      left: 24px;
      top: 3.5rem;
    `}
  }

  @media (max-width: 767px) {
    ${(props) =>
      props.isFixed === "fixed" &&
      `
      position: fixed;
      display: block;
      width: 100%;
      background: #F3F3F3;    
      left: 0px;
      padding: 11px 8px;      
      top: 0px;
      font-size: 16px;
      line-height: 18px;
      z-index: 1;      
    `}

    ${(props) =>
      props.isFixed === "fixed-top" &&
      `
      position: fixed;
      display: block;
      width: 100%;
      background: #F3F3F3;    
      left: 0px;
      padding: 11px 8px;      
      top: 67px;
      font-size: 16px;
      line-height: 18px;
      z-index: 1;  
      transition: top 500ms ease 0s; 
    `}
  }
`

const ProductHeaderTags = styled.div`
  order: 3;
  @media (min-width: 768px) {
    grid-area: tags;
  }
`

const ProductHeaderTag = styled.span`
  text-transform: uppercase;
  padding: 0px 20px;
  border-radius: 15px;
  border: 1px solid #000000;
  font-size: 0.75rem;
  margin-right: 5px;
`

const ProductMainContent = styled.div``

const ProductMainContentImage = styled.div`
  position: relative;
  min-height: 260px;

  @media (min-width: 768px) {
    height: 608px;
  }
`

const ProductMainContentDescription = styled.div`
  font-family: "Favorit Pro Light";
  font-size: 1.45rem;
  line-height: 2.125rem;
  opacity: 0;

  .split-parent {
    overflow: hidden;
  }

  .split-child {
    display: inline-block;

    div {
      line-height: 1.25em;
    }
  }

  @media (min-width: 768px) {
    font-size: 2.125rem;
    line-height: 2.4375rem;
    padding-right: 16px;
    margin-top: 28px;
  }
`

const ProductStoryBlocks = styled.div``
