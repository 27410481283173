import React, { useContext, useState, useEffect, useRef } from "react"
import { SiteContext } from "context/SiteContext"
import { isMobile, isDesktop } from "react-device-detect"
import Scroll from "react-scroll"
import styled, { css } from "styled-components"
import Popup from "reactjs-popup"
import LazyImage from "./LazyImage"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { getLinkTarget } from "utils/helpers"

type NavigationItems = {
  title: string
  link: string
  isGoTo: boolean
  background_color: string
  text_color: string
  isMega?: boolean
  block?: any
  visibility: string
}

interface NavigationBarProps {
  leftItems: Array<NavigationItems> | []
  rightItems?: Array<NavigationItems> | []
  sticky: boolean
  toggleHeader?: any
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  leftItems,
  rightItems,
  sticky,
  toggleHeader,
}) => {
  const [popupMainColor, setPopupMainColor] = useState("#FFFFFF")
  const [popupGapToTop, setPopupGapToTop] = useState(10)
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const navRef = useRef(null)

  const { currentHeaderScrollPosition } = useContext(SiteContext)
  const location = useLocation()

  // Mobile specific components
  const [navigationPopupOpen, setNavigationPopupOpen] = useState(false)
  const closeNavigationPopup = () => setNavigationPopupOpen(false)
  const modalNavigationStyles = {
    "--main-color": popupMainColor,
  } as React.CSSProperties

  useEffect(() => {
    ;(leftItems || [])
      .filter((item) => !item.isMega)
      .map((item) => {
        if (
          (item.link.linktype === "story" &&
            location.pathname.includes(item.link.story.slug)) ||
          (item.link.url !== "" && location.search.includes(item.link.url))
        ) {
          setActiveMenuItem(item.title)
        }
      })

    if (location) {
      const queryParams = new URLSearchParams(location.search)
      const sectionName = queryParams.get("section")
      if (sectionName) {
        Scroll.scroller.scrollTo(sectionName, {
          duration: 1500,
          delay: 100,
          smooth: true,
          offset: 100,
        })
      }
    }
  }, [])

  useEffect(() => {
    const clearableItem = leftItems.find(
      (item) => item.title === activeMenuItem && item.isMega
    )
    clearableItem ? setActiveMenuItem(null) : null // Menu shouldn't stay open on scroll [for mega menu items only]
  }, [currentHeaderScrollPosition])

  useEffect(() => {
    activeMenuItem !== null ? toggleHeader(true) : toggleHeader(false)

    const isMobileViewport = window.matchMedia("(max-width: 767px)")
    if (
      typeof document !== "undefined" &&
      isMobileViewport.matches &&
      activeMenuItem !== null
    ) {
      const activeDOMItem = document.querySelector(".navigation__item.active")
      if (activeDOMItem) {
        activeDOMItem.scrollIntoView({ inline: "center" })
      }
    }
  }, [activeMenuItem])

  const handleClick = (item, event) => {
    event.stopPropagation() // Prevent bubble up
    if (item) {
      if (item.title) {
        if (item.title !== activeMenuItem) {
          setActiveMenuItem(item.title)

          if (isMobile && !navigationPopupOpen && item.isMega) {
            const gapToTop = navRef?.current.getBoundingClientRect().y
            const heightToTop = navRef?.current.getBoundingClientRect().height
            const modalOffset = gapToTop + heightToTop

            modalOffset > 0 ? setPopupGapToTop(modalOffset) : null

            if (item.block?.content_color?.color) {
              setPopupMainColor(item.block?.content_color?.color)
            }
            setNavigationPopupOpen(true)
          }
        } else {
          // Make sure we only close menu with menuItem click
          if (item.isMega) {
            event.target.classList.contains("navigation__item--anchor") ||
            event.target.classList.contains("navigation__item--label")
              ? setActiveMenuItem(null)
              : null
          }
        }
      }
      item.isGoTo === true
        ? Scroll.scroller.scrollTo(item?.link?.url, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -50,
          })
        : null
    }
  }

  const handleMenuClose = () => {
    activeMenuItem ? setActiveMenuItem(null) : null
  }

  const MegamenuStyles = (item) => {
    const backgroundImage = item.block?.background_image?.filename
    const backgroundColor = item.block?.background_color?.color
      ? item.block?.background_color?.color
      : "#FFFFFF"
    const contentColor = item.block?.content_color?.color
      ? item.block?.content_color?.color
      : "#000000"
    const itemBackground = item?.background_color
      ? item?.background_color
      : backgroundColor
    const itemTextColor = item?.text_color ? item?.text_color : contentColor

    const NavigationMegaStyles = {
      "--background-image": `url(${backgroundImage})`,
      "--background-color": backgroundColor,
      "--color": contentColor,
    } as React.CSSProperties

    const NavigationItemStyles = {
      "--item-color": itemTextColor,
      "--item-background-color": itemBackground,
      "--accent-item-color": backgroundColor,
    } as React.CSSProperties

    return { NavigationMegaStyles, NavigationItemStyles }
  }

  return (
    <>
      <NavigationWrapper
        className={
          "navigation__main flex sm:justify-between justify-around items-center md:pl-28 md:pr-6 mt-auto w-screen overflow-x-scroll md:overflow-visible md:w-full"
        }
        onClick={() => handleMenuClose()}
        isActive={activeMenuItem !== null}
        ref={navRef}
      >
        {leftItems && (
          <NavigationList className="navigation" position="left">
            {(leftItems || []).map((item, index) => {
              const { NavigationMegaStyles, NavigationItemStyles } =
                MegamenuStyles(item)

              return (
                <NavigationItem
                  className={[
                    "navigation__item mb-0 inline-flex",
                    activeMenuItem === item.title ? "active" : "",
                    item.visibility === "mobile" ? "md:hidden" : "",
                    item.visibility === "desktop"
                      ? "hidden md:inline-flex"
                      : "",
                  ].join(" ")}
                  onClick={(e) => handleClick(item, e)}
                  key={`navigation__item--${index}`}
                >
                  {!item.isMega && !item.isGoTo ? (
                    item.link?.linktype === "url" ? (
                      <NavigationExternalLink
                        className={`navigation__item--anchor`}
                        style={NavigationItemStyles}
                        href={`${getLinkTarget(item.link)}`}
                      >
                        <span className={`navigation__item--label`}>
                          {item.title}
                        </span>
                      </NavigationExternalLink>
                    ) : (
                      <NavigationLink
                        className={`navigation__item--anchor`}
                        style={NavigationItemStyles}
                        to={`${getLinkTarget(item.link)}`}
                      >
                        <span className={`navigation__item--label`}>
                          {item.title}
                        </span>
                      </NavigationLink>
                    )
                  ) : !item.isGoTo ? (
                    <NavigationLink
                      className={`navigation__item--anchor`}
                      style={NavigationItemStyles}
                    >
                      <span className={`navigation__item--label`}>
                        {item.title}
                      </span>
                    </NavigationLink>
                  ) : (
                    <NavigationLink
                      className={`navigation__item--anchor`}
                      style={NavigationItemStyles}
                      to={
                        location && location.pathname !== "/"
                          ? `/?section=${item.link.url}`
                          : `?section=${item.link.url}`
                      }
                    >
                      <span className={`navigation__item--label`}>
                        {item.title}
                      </span>
                    </NavigationLink>
                  )}
                  {item.isMega && isDesktop ? (
                    <NavigationMega
                      className={item.block?.layout}
                      style={NavigationMegaStyles}
                    >
                      <div className="navigation__item--inner">
                        {item.block?.heading || item.block?.sub_heading ? (
                          <div className="navigation__item--content-header">
                            {item.block?.heading && (
                              <h2 className="navigation__item--header-title uppercase font-fp-regular">
                                {item.block?.heading}
                              </h2>
                            )}
                            {item.block?.sub_heading && (
                              <h3 className="navigation__item--header-subtitle font-fp-light">
                                {item.block?.sub_heading}
                              </h3>
                            )}
                          </div>
                        ) : null}
                        <div className="navigation__item--content-body">
                          {item.block?.logos && (
                            <div className="navigation__item--content-logos">
                              {(item.block?.logos || []).map((logo) =>
                                logo.html ? (
                                  <div
                                    className="navigation__item--content-svg"
                                    dangerouslySetInnerHTML={{
                                      __html: logo.html,
                                    }}
                                  ></div>
                                ) : logo.image ? (
                                  <LazyImage
                                    className={`navigation__item--content-image`}
                                    src={logo.image?.filename}
                                    alt={logo.image?.alt}
                                    resize={"180x0"}
                                  />
                                ) : null
                              )}
                            </div>
                          )}
                          <div
                            className="navigation__item--content-copy"
                            dangerouslySetInnerHTML={{
                              __html: item.block?.content?.content,
                            }}
                          ></div>
                        </div>
                      </div>
                    </NavigationMega>
                  ) : null}
                </NavigationItem>
              )
            })}
          </NavigationList>
        )}
        {rightItems && (
          <NavigationList className="topbar__navigation" position="right">
            {(rightItems || []).map((item, index) => {
              return (
                <NavigationItem
                  className="topbar__navigation--item"
                  key={`navigation__item--${index}`}
                >
                  <NavigationLink
                    className={`font-fp-light secondary`}
                    secondary={true}
                    stickyColor={sticky}
                    href={item.link?.url}
                  >
                    {item.title}
                  </NavigationLink>
                </NavigationItem>
              )
            })}
          </NavigationList>
        )}
      </NavigationWrapper>
      {isMobile ? (
        <NavigationModal className="navigation__item--modal">
          <NavigationPopup
            open={navigationPopupOpen}
            closeOnDocumentClick
            onClose={closeNavigationPopup}
            offsetY={popupGapToTop}
          >
            <NavigationModalContent className="navigation__item--content">
              <NavigationModalClose
                onClick={closeNavigationPopup}
                style={modalNavigationStyles}
              >
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 27 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="2.72199"
                    y1="1.30809"
                    x2="25.722"
                    y2="25.3081"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <line
                    y1="-1"
                    x2="33.2415"
                    y2="-1"
                    transform="matrix(-0.691905 0.721988 0.721988 0.691905 25 2)"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              </NavigationModalClose>
              {(leftItems || [])
                .filter((item) => item.isMega === true)
                .map((item, index) => {
                  const { NavigationMegaStyles } = MegamenuStyles(item)

                  return (
                    <NavigationModalMain
                      className={item.block?.layout}
                      style={NavigationMegaStyles}
                      isActive={activeMenuItem === item.title}
                      key={`navigation__item--main-${index}`}
                    >
                      <div className="navigation__item--inner">
                        {item.block?.heading || item.block?.sub_heading ? (
                          <div className="navigation__item--content-header">
                            {item.block?.heading && (
                              <h2 className="navigation__item--header-title uppercase font-fp-regular">
                                {item.block?.heading}
                              </h2>
                            )}
                            {item.block?.sub_heading && (
                              <h3 className="navigation__item--header-subtitle font-fp-light">
                                {item.block?.sub_heading}
                              </h3>
                            )}
                          </div>
                        ) : null}
                        <div className="navigation__item--content-body">
                          {item.block?.logos && (
                            <div className="navigation__item--content-logos">
                              {(item.block?.logos || []).map((logo) =>
                                logo.html ? (
                                  <div
                                    className="navigation__item--content-svg"
                                    dangerouslySetInnerHTML={{
                                      __html: logo.html,
                                    }}
                                  ></div>
                                ) : logo.image ? (
                                  <LazyImage
                                    className={`navigation__item--content-image`}
                                    src={logo.image?.filename}
                                    alt={logo.image?.alt}
                                    resize={"180x0"}
                                  />
                                ) : null
                              )}
                            </div>
                          )}
                          {item.block?.content_mobile?.content ? (
                            <div
                              className="navigation__item--content-copy"
                              dangerouslySetInnerHTML={{
                                __html: item.block?.content_mobile?.content,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="navigation__item--content-copy"
                              dangerouslySetInnerHTML={{
                                __html: item.block?.content?.content,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </NavigationModalMain>
                  )
                })}
            </NavigationModalContent>
          </NavigationPopup>
        </NavigationModal>
      ) : null}
    </>
  )
}

const NavigationWrapper = styled.nav`
  position: relative;
  font-size: 0px;
  top: 0px;
  z-index: 3;
  transition: background-color 0.2s ease-out;
`

const NavigationModal = styled.div``

const NavigationModalContent = styled.div``

const NavigationModalMain = styled.div`
  display: none;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--background-color);
  color: var(--color);
  padding: 1rem 2rem;

  .navigation__item--header-title {
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .navigation__item--header-subtitle {
    text-align: center;
    font-size: 2.625rem;
    line-height: 3.125rem;
    margin-bottom: 2rem;
  }

  .navigation__item--content-logos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    max-width: calc(100vw - 120px);
    margin: 0 auto 20px auto;
    justify-content: space-between;
    justify-items: center;
  }

  .navigation__item--content-svg {
    width: 100%;
    max-width: 58px;
    padding: 5px 0px;
  }

  &.layout-1 {
    .navigation__item--content-body {
      font-size: 1.5rem;
      line-height: 2.125rem;
      text-align: left;
    }
  }

  &.layout-2 {
    .navigation__item--content-body {
      font-size: 1rem;
      line-height: 1.375rem;
      text-align: left;
    }
  }

  &.layout-3 {
    .navigation__item--content-body {
      font-family: "Favorit Pro Light";
      font-size: 22px;
      line-height: 30px;
      text-align: left;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  ${(props) =>
    props.isActive &&
    `
    display: flex;
  `}
`

const NavigationPopup = styled(Popup)`
  &-content {
    background-color: #ffffff;
    top: ${(props) => `${props.offsetY}px`};
  }
`

const NavigationModalClose = styled.a`
  position: absolute;
  top: 1.125rem;
  right: 0.875rem;
  box-shadow: none;
  cursor: pointer;

  svg line {
    stroke: var(--main-color);
  }
`

const NavigationList = styled.ul`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.position === "left"
      ? `
    margin-left: 90px;
    max-width: calc(100vw - 90px);

    @media (min-width: 768px) {
      margin-left: 0px;
      max-width: 100%;
      padding-left: 0px;
    }
  `
      : `
    margin-left: auto;
    display: none;

    @media (min-width: 768px) {
      display: block;
      margin-top: 15px;
    }
  `}
`

const NavigationItem = styled.li`
  &:hover,
  &.active {
    a {
      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &.active {
    > div {
      opacity: 1;
      top: 63px;
      transition: top 0.2s ease-out, opacity 0.8s 0.3s ease;
    }
    a {
      color: var(--item-color);
      &:before {
        background: var(--item-background-color);
        opacity: 1;

        @media (min-width: 768px) {
        }
      }
    }
  }
`

const sharedNavigationStyles = css`
  position: relative;
  font-family: "Favorit Pro Regular";
  font-size: 14px;
  line-height: 16px;
  z-index: 1;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  padding: 16px 5px;

  @media (min-width: 768px) {
    padding: 16px 10px;
  }

  @media (min-width: 1024px) {
    padding: 16px 15px;
  }

  @media (min-width: 1280px) {
    padding: 16px 30px;
  }

  &:before {
    content: "";
    position: absolute;
    background: var(--item-background-color);
    z-index: 0;
    border-radius: 25px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 104px;
    left: calc((100% / 2) - 52px);
    margin: 0 auto;
    transition: width 0.3s, height 0.6s, opacity 0.5s;
    opacity: 0;

    @media (min-width: 768px) {
      width: 110px;
      left: calc((100% / 2) - 55px);
      height: calc(100% + 0px);
      border-radius: 50px;
      padding: 16px 5px;
    }

    @media (min-width: 1024px) {
      width: 160px;
      left: calc((100% / 2) - 80px);
      height: calc(100% + 10px);
      padding: 16px 30px;
    }
  }

  &.secondary {
    border-radius: 25px;
  }

  > span {
    display: block;
    position: relative;
    z-index: 1;
    min-width: 100px;
    text-align: center;

    @media (min-width: 768px) {
      min-width: 80px;
    }

    @media (min-width: 1024px) {
      min-width: 100px;
    }
  }
`

const NavigationExternalLink = styled.a`
  ${sharedNavigationStyles}
`

const NavigationLink = styled(Link)`
  ${sharedNavigationStyles}

  ${(props) =>
    props.secondary &&
    `
    color: #0029C8;
    font-size: 0.875rem;

    &:hover {
      color: #0029C8 !important;
      border: 1px solid #0029C8;
    }
  `}

  ${(props) =>
    props.stickyColor &&
    props.secondary &&
    `
    color: #0029C8;
  `}
`

const NavigationMega = styled.div`
  position: absolute;
  display: flex;
  opacity: 0;
  top: calc(-100vh - 168px);
  left: 0;
  bottom: auto;
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: 80px 0px 168px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--background-image);
  background-color: var(--background-color);
  color: var(--color);
  z-index: 99;

  .navigation__item--inner {
    max-width: calc(100vw - 14rem);
    margin: 0 auto;
  }

  .navigation__item--content-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 50px;
    max-width: 800px;
    margin: 0px auto 30px auto;
  }

  .navigation__item--content-svg {
    max-width: 140px;
    margin: 30px;
    svg {
      width: 100%;
    }
  }

  &.layout-3 {
    .navigation__item--inner {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      .navigation__item--content-header {
        .navigation__item--header-title {
          font-size: 14px;
          margin-bottom: 4rem;
          text-align: center;
        }
        .navigation__item--header-subtitle {
          font-size: 4rem;
          text-align: center;
        }
      }
      .navigation__item--content-body {
        font-family: "Favorit Pro Light";
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        margin-top: 4rem;
        max-width: 1008px;
      }
    }
  }

  &.layout-2 {
    .navigation__item--inner {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      .navigation__item--content-header {
        .navigation__item--header-title {
          font-size: 1.25rem;
          margin-bottom: 1rem;
          text-align: center;
        }
        .navigation__item--header-subtitle {
          font-size: 4rem;
          margin-bottom: 190px;
          text-align: center;
        }
      }
      .navigation__item--content-body {
        column-count: 3;
      }
    }
  }

  &.layout-1 {
    .navigation__item--inner {
      display: flex;
      flex-direction: row;

      .navigation__item--content-header {
        display: flex;
        width: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .navigation__item--header-title {
          font-size: 1.25rem;
        }

        .navigation__item--header-subtitle {
          text-align: center;
          font-size: 4rem;
        }
      }

      .navigation__item--content-body {
        width: 50%;
        font-size: 2.125rem;
        line-height: 2.4rem;
      }
    }
  }
`
