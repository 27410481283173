module.exports = {
  siteMetadata: {
    title: `Sunshine Enclosures`,
    author: `Sunshine Enclosures`,
    description: `Sunshine Enclosures provides bespoke packaging for quality cannabis.`,
    siteUrl: `https://sunshineenclosures.com/`,
    social: {
      instagram: `sunshine_enclosures`,
    },
  },
  plugins: [
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "3Z5LEGCjSWFR8mjwNYDSWAtt",
        homeSlug: "index",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
        resolveLinks: "story",
      },
    },
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-offline`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-postcss`,
    `gatsby-plugin-typescript`,
    `gatsby-plugin-image`,
    `gatsby-plugin-root-import`,
    `gatsby-transformer-json`,
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Sunshine Enclosures`,
        short_name: `SE`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#ec3e3f`,
        display: `minimal-ui`,
        // edit below
        icon: `content/assets/icon.png`,
      },
    },
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/utils/typography`,
      },
    },
  ],
}
