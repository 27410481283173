import React, { createRef, useEffect, useRef } from "react"
import styled from "styled-components"
import { StoryProps } from "../shared-types"
import { getLinkTarget, handleize } from "../utils/helpers"
import { gsap } from "gsap/all"
import LazyImage from "./LazyImage"
import { useState } from "react"
import { useLocation } from "@reach/router"
import { useSwipeable } from "react-swipeable"
import Slider from "react-slick"
import { Link } from "gatsby"

interface ContentSlideTagsProps {
  tags: string | Array<string>
}

const ContentSlideTags: React.FC<ContentSlideTagsProps> = (props) => {
  const { tags } = props

  const tagList = Array.isArray(tags) ? tags : tags.split(",")

  return (
    <ContentSliderTags className="content-slider__tags">
      {tagList.map((tag, index) => (
        <ContentSliderTag
          className={`mx-1`}
          key={`content-slider__tags--tag-${index}`}
        >
          {tag}
        </ContentSliderTag>
      ))}
    </ContentSliderTags>
  )
}

export const ContentSlider: React.FC<StoryProps> = (props) => {
  const {
    blok: { _uid, section_id, blocks },
  } = props

  const location = useLocation()
  const MOBILE_NAV_SETTINGS = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "80px",
    variableWidth: false,
    focusOnSelect: false,
    adaptiveHeight: false,
  }

  const blocksLength = blocks.length
  const [contentRefs, setContentRefs] = useState([])
  const [copyRefs, setCopyRefs] = useState([])

  const mainRef = useRef(null)
  const navRef = useRef(null)
  const contentRef = useRef(null)
  const copyRef = useRef(null)
  const sliderRef = useRef(null)

  const [activeTab, setActiveTab] = useState(null)
  const [focusedTab, setFocusedTab] = useState(null)
  const sectionName = section_id ? handleize(section_id) : _uid

  useEffect(() => {
    setContentRefs((contentRefs) =>
      Array(blocksLength)
        .fill()
        .map((_, i) => contentRefs[i] || createRef())
    )

    setCopyRefs((copyRefs) =>
      Array(blocksLength)
        .fill()
        .map((_, i) => copyRefs[i] || createRef())
    )
  }, [blocksLength])

  useEffect(() => {
    // Update visible image to match current tab
    contentRefs.map((contentRef) => {
      if (focusedTab === contentRef.current.getAttribute("data-uid")) {
        gsap.timeline().to(contentRef.current, { duration: 0.4, opacity: 1 })
      }
    })

    // Update visible copy to match current tab
    copyRefs.map((copyRef) => {
      if (focusedTab === copyRef.current.getAttribute("data-uid")) {
        gsap.timeline().to(copyRef.current, { duration: 0.7, opacity: 1 })
      }
    })

    // Update current slick slide on mobile
    const isMobileViewport = window.matchMedia("(max-width: 767px)")
    if (typeof document !== "undefined" && isMobileViewport.matches) {
      //sliderRef.current.slickGoTo
      const activeSlide = document
        .querySelector(".content-slider__nav--item.active")
        ?.closest(".slick-slide")
      if (activeSlide) {
        sliderRef.current.slickGoTo(activeSlide.getAttribute("data-index"))
      }
    }
  }, [focusedTab])

  useEffect(() => {
    let selectedTabId = blocks[Math.floor(Math.random() * blocks.length)]._uid
    if (location) {
      const queryParams = new URLSearchParams(location.search)
      const selectedTab = blocks.find(
        (block) => block._uid === queryParams.get("tab")
      )
      if (selectedTab) {
        selectedTabId = selectedTab._uid
      }
    }
    setActiveTab(selectedTabId)
    setFocusedTab(selectedTabId)
  }, [])

  const handleTabClick = (event) => {
    const tabUid = event.target.getAttribute("data-uid")
    if (tabUid !== activeTab) {
      setActiveTab(tabUid)
      setFocusedTab(tabUid)
    }
  }

  const handleTabHover = (event) => {
    if (event.type === "mouseenter") {
      const tabUid = event.target?.getAttribute("data-uid")
      setFocusedTab(tabUid)
    } else {
      setFocusedTab(activeTab)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: (_eventData) => {
      const currentIndex = blocks.findIndex((block) => block._uid === activeTab)
      const targetBlock =
        currentIndex + 1 < blocks.length
          ? blocks[currentIndex + 1]._uid
          : blocks[0]._uid
      setActiveTab(targetBlock)
      setFocusedTab(targetBlock)
    },
    onSwipedRight: (_eventData) => {
      const currentIndex = blocks.findIndex((block) => block._uid === activeTab)
      const targetBlock =
        currentIndex > 0
          ? blocks[currentIndex - 1]._uid
          : blocks[blocks.length - 1]._uid
      setActiveTab(targetBlock)
      setFocusedTab(targetBlock)
    },
    ...{},
  })

  return (
    <>
      {Array.isArray(blocks) && blocks.length > 0 && (
        <ContentSliderWrapper
          name={sectionName}
          id={sectionName}
          ref={mainRef}
          className="content-slider__main md:grid md:grid-cols-16"
        >
          <ContentSliderNav
            ref={navRef}
            className="content-slider__nav md:col-span-5 block md:grid md:h-screen"
          >
            <Slider
              className="md:hidden"
              ref={(slider) => (sliderRef.current = slider)}
              {...MOBILE_NAV_SETTINGS}
            >
              {blocks.map((block, index) => (
                <ContentSliderNavTabMobile
                  className={`content-slider__nav--item no-underline ${
                    activeTab === block._uid && "active"
                  } ${focusedTab === block._uid ? "focused" : ""}`}
                  data-uid={block._uid}
                  key={`content-slider__tab--${index}`}
                >
                  <span onClick={handleTabClick} data-uid={block._uid}>
                    {block.label}
                  </span>
                </ContentSliderNavTabMobile>
              ))}
            </Slider>
            <ContentSliderNavTabList
              className={
                "hidden md:flex md:flex-row md:flex-col md:justify-center"
              }
            >
              {blocks.map((block, index) => (
                <ContentSliderNavTab
                  className={`no-underline font-fp-light ${
                    activeTab === block._uid ? "active" : null
                  } ${focusedTab === block._uid ? "focused" : null}`}
                  key={`content-slider__tab--${index}`}
                >
                  <span
                    onMouseEnter={handleTabHover}
                    onMouseLeave={handleTabHover}
                    onClick={handleTabClick}
                    data-uid={block._uid}
                  >
                    {block.label}
                  </span>
                </ContentSliderNavTab>
              ))}
            </ContentSliderNavTabList>
          </ContentSliderNav>
          <ContentSliderDisplay
            ref={contentRef}
            className="content-slider__display col-span-6"
            {...handlers}
          >
            {blocks.map((block, index) => (
              <ContentSliderScreen
                ref={contentRefs[index]}
                className={`content-slider__screen w-full ${
                  activeTab === block._uid && activeTab === focusedTab
                    ? "active"
                    : ""
                } ${focusedTab === block._uid ? "focused" : ""}`}
                key={`content-slider__screen--${index}`}
                data-uid={block._uid}
              >
                {block.tags && <ContentSlideTags tags={block.tags} />}
                {block?.image_xs?.filename ? (
                  <LazyImage
                    className={`absolute block md:hidden object-cover object-center w-full h-full`}
                    src={block?.image_xs?.filename}
                    alt={block.label}
                    ratio="1/1"
                    resize="0x768"
                  />
                ) : null}
                <LazyImage
                  className={[
                    "absolute object-cover object-center w-full h-full",
                    block?.image_xs?.filename ? "hidden md:block" : "",
                  ].join(" ")}
                  src={block?.image?.filename}
                  alt={block.label}
                  ratio="4/3"
                />
              </ContentSliderScreen>
            ))}
          </ContentSliderDisplay>
          <ContentSliderCaption ref={copyRef} className="md:col-span-5">
            {blocks.map((block, index) => (
              <ContentSliderCaptionText
                ref={copyRefs[index]}
                className={`content-slider__caption w-full ${
                  activeTab === block._uid && activeTab === focusedTab
                    ? "active"
                    : ""
                } ${focusedTab === block._uid ? "focused" : ""}`}
                key={`content-slider__caption--${index}`}
                data-uid={block._uid}
              >
                <ContentSliderCaptionExcerpt className="content-slider__caption--excerpt">
                  {block?.excerpt}
                  <Link
                    className="content-slider__caption--cta text-xs text-center bg-blue-regular py-1.5 uppercase font-fp-book w-28 mx-auto rounded-full text-white"
                    to={`${getLinkTarget(
                      block?.cta_link
                    )}?section=${sectionName}&tab=${block._uid}`}
                  >
                    {block?.cta_label}
                  </Link>
                </ContentSliderCaptionExcerpt>
              </ContentSliderCaptionText>
            ))}
          </ContentSliderCaption>
        </ContentSliderWrapper>
      )}
    </>
  )
}

const ContentSliderWrapper = styled.div`
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: calc(100vw - 50px);
  }
`

const ContentSliderNav = styled.nav`
  position: relative;
`

const ContentSliderNavTabList = styled.ul`
  overflow: visible;
`

const ContentSliderNavTab = styled.li`
  margin: 0px;
  span {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 300ms ease-in, font-size 200ms;
    padding: 0.75rem 0rem;

    &:hover {
      opacity: 1;
    }
  }

  &.active,
  &.focused {
    span {
      opacity: 1;
    }
  }

  &.active,
  &.focused {
    span {
      font-size: 20px;
    }
    &:after {
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      background: #0029c8;
      border-radius: 100%;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  @media (min-width: 1024px) {
    span {
      font-size: 20px;
      line-height: 24px;
    }

    &.active,
    &.focused {
      span {
        font-size: 24px;
      }
    }
  }
`

const ContentSliderNavTabMobile = styled.div`
  position: relative;
  padding: 25px 5px 15px 5px;
  opacity: 0.4;
  width: 60vw !important;
  text-align: center;
  outline: none;

  span {
    font-family: "Favorit Pro Light";
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    outline: none;

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      display: block;
      background: #0029c8;
      border-radius: 100%;
      margin: -23px auto 5px auto;
    }
  }
`

const ContentSliderDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 375px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100vh;
    overflow: visible;
  }
`

const ContentSliderScreen = styled.div`
  position: relative;
  display: none;

  &.active:not(.focused),
  &.focused {
    display: block;
    opacity: 0;
  }

  @media (min-width: 768px) {
    height: 100vh;
  }
`

const ContentSliderCaption = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 260px;

  @media (min-width: 768px) {
    height: 100vh;
    min-height: 60vh;
  }
`

const ContentSliderCaptionText = styled.div`
  display: none;
  margin-top: 36px;
  padding: 0px 8px;

  &.active:not(.focused),
  &.focused {
    display: flex;
    opacity: 0;
  }

  .content-slider__caption--cta {
    margin-bottom: 36px;
    order: -1;
  }

  @media (min-width: 768px) {
    height: 100vh;
    margin-top: auto;
    padding: 0px;

    .content-slider__caption--cta {
      margin-top: 60px;
      margin-bottom: 0px;
      order: 1;
    }
  }
`

const ContentSliderCaptionExcerpt = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Favorit Pro Regular";
  font-size: 15px;
  line-height: 22px;
  align-self: center;
  margin: 0px 25px 20px 25px;

  @media (min-width: 1024px) {
    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: 40px;
  }
`

const ContentSliderTags = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  left: 1.5rem;
  bottom: 1.875rem;
  z-index: 5;
`

const ContentSliderTag = styled.div`
  padding: 4px 20px;
  border: 1px solid #000000;
  text-align: center;
  text-transform: uppercase;
  border-radius: 40px;
  margin-right: 3px;
  margin-bottom: 4px;
`
