import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap, ScrollTrigger } from "gsap/all"

export const MainSlideshow: React.FC = (props) => {
  const { blok, children } = props

  gsap.registerPlugin(ScrollTrigger)

  const slideshowRef = useRef(null)
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ".slideshow-gh",
      start: "top top",
      pin: true,
      pinSpacing: false,
    })
  }, [])

  return (
    <>
      <Slideshow ref={slideshowRef} className="slideshow flex flex-col">
        {blok &&
          (blok.slides || []).map((slide, index) => {
            const backgroundImage = {
              "--background-image": `url(${slide?.image?.filename})`,
              "--background-image-xs": `url(${slide?.image_xs?.filename})`,
            } as React.CSSProperties
            return (
              <SlideshowScene
                className={`slideshow__scene ${slide?.caption_alignment} mobile--${slide?.caption_alignment_mobile}`}
                key={`slideshow__scene--${index}`}
                style={backgroundImage}
              >
                <SlideshowHeading
                  className={`slideshow__scene--heading text--${slide?.caption_text_alignment} text-mobile--${slide?.caption_text_alignment_mobile}`}
                >
                  {slide?.caption}
                </SlideshowHeading>
              </SlideshowScene>
            )
          })}
        {children}
      </Slideshow>
    </>
  )
}

const Slideshow = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  justify-item: center;
  height: 100vh;
  overflow: hidden;
`

const SlideshowScene = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-attachment: initial;
  background-position: center;
  background-image: var(--background-image);
  background-image: var(--background-image-xs);

  @media (max-width: 767px) {
    &.mobile--left-top {
      > .slideshow__scene--heading {
        left: 80px;
        top: 60px;
      }
    }

    &.mobile--center-top {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 60px;
      }
    }

    &.mobile--right-top {
      > .slideshow__scene--heading {
        right: 80px;
        top: 60px;
      }
    }

    &.mobile--left-middle {
      > .slideshow__scene--heading {
        left: 80px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.mobile--center-middle {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.mobile--right-middle {
      > .slideshow__scene--heading {
        right: 80px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.mobile--left-bottom {
      > .slideshow__scene--heading {
        left: 80px;
        bottom: 60px;
      }
    }

    &.mobile--center-bottom {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 60px;
      }
    }

    &.mobile--right-bottom {
      > .slideshow__scene--heading {
        right: 80px;
        bottom: 60px;
      }
    }
  }

  @media (min-width: 768px) {
    background-attachment: fixed;
    background-image: var(--background-image);

    &.left-top {
      > .slideshow__scene--heading {
        left: 80px;
        top: 60px;
      }
    }

    &.center-top {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 60px;
      }
    }

    &.right-top {
      > .slideshow__scene--heading {
        right: 80px;
        top: 60px;
      }
    }

    &.left-middle {
      > .slideshow__scene--heading {
        left: 80px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.center-middle {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.right-middle {
      > .slideshow__scene--heading {
        right: 80px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.left-bottom {
      > .slideshow__scene--heading {
        left: 80px;
        bottom: 60px;
      }
    }

    &.center-bottom {
      > .slideshow__scene--heading {
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 60px;
      }
    }

    &.right-bottom {
      > .slideshow__scene--heading {
        right: 80px;
        bottom: 60px;
      }
    }
  }
`

const SlideshowHeading = styled.h2`
  font-family: "Favorit Pro Light";
  font-size: 34px;
  line-height: 1em;
  position: absolute;
  bottom: 60px;
  right: 20px;
  z-index: 1;
  max-width: 70vw;
  height: fit-content;

  @media (max-width: 767px) {
    &.text-mobile--left {
      text-align: left;
    }
    &.text-mobile--center {
      text-align: center;
    }
    &.text-mobile--right {
      text-align: right;
    }
  }

  @media (min-width: 768px) {
    font-size: 2.56rem;
    line-height: 3rem;
    max-width: auto;

    &.text--left {
      text-align: left;
    }
    &.text--center {
      text-align: center;
    }
    &.text--right {
      text-align: right;
    }
  }
`
