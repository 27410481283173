import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import LazyLoad, { forceVisible } from "react-lazyload"
import { useEffect } from "react"
import gsap from "gsap/all"
import { isInViewport } from "../utils/helpers"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => `
    padding-bottom: calc(${props.ratio ? props.ratio : "0"} * 100%);
  `}
`

const loadingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
`

interface LazyImageProps {
  src: string
  alt: string
  className?: string
  isSticky?: boolean
  resize?: string
  fadeInOnScroll?: boolean
  ratio?: string
}

const LazyImage: React.FC<LazyImageProps> = (props) => {
  const { src, alt, className, resize, fadeInOnScroll, ratio } = props
  const refPlaceholder = useRef(null)
  const refImage = useRef(null)

  const STORYBLOK_CDN = "https://img2.storyblok.com"
  const [finalSrc, setFinalSrc] = useState(null)

  const removePlaceholder = () => {
    if (!fadeInOnScroll) {
      refPlaceholder.current.remove()
      gsap.to(refImage.current, {
        duration: 0.5,
        opacity: 1,
      })
    }
  }

  useEffect(() => {
    if (!resize) {
      setFinalSrc(src)
    } else {
      const dynamicUrl = src ? src.replace(/^.*\/\/[^\/]+/, "") : ""
      setFinalSrc(`${STORYBLOK_CDN}/${resize}/smart${dynamicUrl}`)
    }
    forceVisible()
  }, [props])

  useScrollPosition(() => {
    if (
      fadeInOnScroll &&
      refImage.current &&
      isInViewport(refImage.current, 20)
    ) {
      gsap.to(refImage.current, {
        duration: 2,
        opacity: 1,
        ease: "elastic.out(1, 0.3)",
        y: -50,
        delay: Math.floor(Math.random() * (1 - 0.1 + 1) + 0.1),
      })
    }
  }, [refImage, fadeInOnScroll])

  return (
    <ImageWrapper className={className} ratio={ratio}>
      {!fadeInOnScroll && <Placeholder ref={refPlaceholder} />}
      {finalSrc && (
        <LazyLoad offset={200}>
          <StyledImage
            onLoad={removePlaceholder}
            onError={removePlaceholder}
            src={finalSrc}
            alt={alt}
            ref={refImage}
          />
        </LazyLoad>
      )}
    </ImageWrapper>
  )
}

export default LazyImage
