export const handleize = function (str) {
  str = str.toLowerCase()

  const toReplace = ['"', "'", "\\", "(", ")", "[", "]"]

  // For the old browsers
  for (let i = 0; i < toReplace.length; ++i) {
    str = str.replace(toReplace[i], "")
  }

  str = str.replace(/\W+/g, "-")

  if (str.charAt(str.length - 1) == "-") {
    str = str.replace(/-+\z/, "")
  }

  if (str.charAt(0) == "-") {
    str = str.replace(/\A-+/, "")
  }

  return str
}

export const getLinkTarget = (link) => {
  let blockLink = ""
  switch (link?.linktype) {
    case "story":
      blockLink = `/${link?.cached_url}`
      break
    case "url":
      blockLink = link?.url
      break
    default:
      blockLink = link
  }
  return blockLink
}

export const isInViewport = (el, percentVisible): boolean => {
  const rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight

  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
      percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  )
}
