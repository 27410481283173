import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

export const Quote: React.FC = (props) => {
  const {
    blok: { cta_label, cta_link, content },
  } = props

  return (
    <QuoteWrapper
      className="quote__wrapper flex flex-col items-center"
      isMobile={isMobile}
    >
      <QuoteHeading
        className="quote__intro text-center font-fp-light"
        isMobile={isMobile}
      >
        {content}
      </QuoteHeading>
      {cta_link?.url && (
        <QuoteLink
          className="bg-blue-regular py-1.5 px-5 rounded-full text-white uppercase font-fp-book text-xs"
          href={cta_link?.url}
        >
          {cta_label}
        </QuoteLink>
      )}
    </QuoteWrapper>
  )
}

const QuoteWrapper = styled.div`
  margin: 0 auto;
  padding: 60px 0px;
  max-width: calc(100vw - 30px);

  @media (min-width: 768px) {
    padding: 100px 0px;
    max-width: calc(100vw - 320px);
  }
`

const QuoteLink = styled(Link)``

const QuoteHeading = styled.h3`
  font-size: 24px;
  margin-bottom: 0px;

  + a {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    font-size: 4rem;
    margin-bottom: 64px;
  }
`
