import React from "react"
import { ProductCards } from "./ProductCards"
import { ContentSlider } from "./ContentSlider"
import { MainSlideshow } from "./MainSlideshow"
import { Quote } from "./Quote"
import { ProductGallery } from "./ProductGallery"
import ProductFlyout from "./ProductsFlyout"
import { Paragraph } from "./Paragraph"
import { Logos } from "./Logos"

const Components = {
  "products-grid": ProductCards,
  "content-slider": ContentSlider,
  "main-slider": MainSlideshow,
  quote: Quote,
  "product-gallery": ProductGallery,
  "product-flyout": ProductFlyout,
  paragraph: Paragraph,
  "logo-gallery": Logos,
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} />
  }

  return blok.component ? <></> : null
}

export default DynamicComponent
