import React from "react"
import styled from "styled-components"
import LazyImage from "./LazyImage"
import { StoryProps } from "../shared-types"

export const ProductGallery: React.FC<StoryProps> = (props) => {
  const {
    blok: { _uid, rows },
  } = props

  const ProductGalleryRowConfig = {
    "1-images": ["col-span-full"],
    "2-images": ["col-span-8", "col-span-8"],
    "3-images": ["col-span-6", "col-span-5", "col-span-5"],
    "4-images": ["col-span-4", "col-span-4", "col-span-4", "col-span-4"],
    "5-images": [
      "col-span-3",
      "col-span-2",
      "col-span-6",
      "col-span-2",
      "col-span-3",
    ],
  }

  return (
    <ProductGalleryWrapper
      id={_uid}
      className="product__gallery flex flex-col md:grid md:grid-cols-16 md:gap-y-20 pb-20"
    >
      {(rows || []).map((row, rowIndex) => {
        const imageCount = row.images ? row.images.length : 0
        const rowConfig = `${imageCount}-images`
        return imageCount > 0
          ? (row.images || []).slice(0, 4).map((image, imageIndex) => (
              <ProductGalleryItem
                key={`product__gallery-item--${imageIndex}`}
                className={`product__gallery-item row-start-${rowIndex + 1} ${
                  ProductGalleryRowConfig[rowConfig][imageIndex]
                }`}
              >
                <LazyImage
                  className={``}
                  src={image?.filename}
                  alt={image?.alt}
                  fadeInOnScroll={true}
                  ratio="3/4"
                />
              </ProductGalleryItem>
            ))
          : null
      })}
    </ProductGalleryWrapper>
  )
}

const ProductGalleryWrapper = styled.div`
  margin: 0px 15px;
  grid-gap: 8px;
`

const ProductGalleryItem = styled.div`
  position: relative;
  width: 100%;
`
