import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import LazyImage from "./LazyImage"

export const Logos: React.FC = (props) => {
  const {
    blok: {
      padding_top,
      padding_bottom,
      margin_top,
      margin_bottom,
      padding_top_mobile,
      padding_bottom_mobile,
      margin_top_mobile,
      margin_bottom_mobile,
      logos,
    },
  } = props

  return (
    <LogosWrapper
      className="logos__wrapper"
      isMobile={isMobile}
      paddingTop={padding_top}
      paddingBottom={padding_bottom}
      marginTop={margin_top}
      marginBottom={margin_bottom}
      paddingTopMobile={padding_top_mobile}
      paddingBottomMobile={padding_bottom_mobile}
      marginTopMobile={margin_top_mobile}
      marginBottomMobile={margin_bottom_mobile}
    >
      {logos.map((logo, index) => (
        <LogoItem className="logos__wrapper--item" key={`logo--${index}`}>
          {logo.html ? (
            <div
              className="navigation__item--content-svg"
              dangerouslySetInnerHTML={{
                __html: logo.html,
              }}
            ></div>
          ) : logo.image ? (
            <LazyImage
              className={`navigation__item--content-image`}
              src={logo.image?.filename}
              alt={logo.image?.alt}
              resize={"180x0"}
            />
          ) : null}
        </LogoItem>
      ))}
    </LogosWrapper>
  )
}

const LogosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  grid-row-gap: 38px;

  ${(props) =>
    props.marginTopMobile ? `margin-top: ${props.marginTopMobile};` : null}
  ${(props) =>
    props.marginBottomMobile
      ? `margin-bottom: ${props.marginBottomMobile};`
      : null}
  ${(props) =>
    props.paddingTopMobile ? `padding-top: ${props.paddingTopMobile}px;` : null}
  ${(props) =>
    props.paddingBottomMobile
      ? `padding-bottom: ${props.paddingBottomMobile}px;`
      : null}

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : null)}
    ${(props) =>
      props.marginBottom ? `margin-bottom: ${props.marginBottom};` : null}
    ${(props) =>
      props.paddingTop ? `padding-top: ${props.paddingTop}px;` : null}
    ${(props) =>
      props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : null}
  }
`

const LogoItem = styled.div``
