import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import { Footer } from "./Footer"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"

import { LayoutProps } from "shared-types"

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, location, bgColor, textColor, paddedContent } = props

  const transparentHeader = location && location.pathname === "/" ? true : false
  const sidebarEnabled = props.sidebarEnabled ? props.sidebarEnabled : false
  const sidebarAlignment = props.sidebarAlignment
    ? props.sidebarAlignment
    : "right"

  const { settings } = useStaticQuery(graphql`
    query Settings {
      settings: storyblokEntry(field_component: { eq: "settings" }) {
        name
        full_slug
        content
      }
    }
  `)

  const siteSettings = JSON.parse(settings.content)

  const mainMenuItems = []
  ;(siteSettings?.navigation[0]?.items || []).map((item) => {
    mainMenuItems.push({
      title: item?.label,
      link: item?.target,
      background_color: item?.background_color?.color,
      text_color: item?.text_color?.color,
      isGoTo: item?.is_goto,
      isMega: item?.is_mega,
      block: item?.block && item?.block.length > 0 ? item?.block[0] : null,
      visibility: item?.visibility,
    })
  })

  const secondaryMenuItems = []
  ;(siteSettings?.topbar_navigation[0]?.items || []).map((item) => {
    secondaryMenuItems.push({
      title: item?.label,
      link: item?.target,
      isGoTo: item?.is_goto,
    })
  })

  const footerSettings =
    siteSettings?.footer && Array.isArray(siteSettings?.footer)
      ? siteSettings?.footer[0]
      : {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <Header
        primaryMenu={mainMenuItems}
        secondaryMenu={secondaryMenuItems}
        transparentHeader={transparentHeader}
      />
      <motion.main
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        <MainWrapper
          className={`${sidebarEnabled ? "has-sidebar" : "grid-cols-1"}`}
          style={{
            "--background-color": bgColor,
            "--text-color": textColor,
          }}
          sidebarEnabled={sidebarEnabled}
          paddedContent={paddedContent}
        >
          {sidebarEnabled && sidebarAlignment === "left" && (
            <Sidebar excludedPost={location.pathname} />
          )}
          <Main
            id="main-content"
            className={`${sidebarEnabled ? "sidebar-padding" : ""}`}
          >
            {children}
          </Main>
          {sidebarEnabled && sidebarAlignment === "right" && (
            <Sidebar excludedPost={location.pathname} />
          )}
        </MainWrapper>
      </motion.main>
      <Footer settings={footerSettings} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-left: auto;
  margin-right: auto;
  background-color: var(--background-color);
  color: var(--text-color);

  ${(props) =>
    props.paddedContent &&
    `    
    min-height: 100vh;
    padding: 120px 20px 40px 20px;

    main {
      display: flex;
      flex-direction: column;
    }
  `}

  @media (min-width: 768px) {
    ${(props) =>
      props.sidebarEnabled &&
      `
      grid-template-columns: 1fr 218px;
    `}

    ${(props) =>
      props.paddedContent &&
      `
      padding: 140px calc((100vw - 700px) / 2) 100px calc((100vw - 700px) / 2);
    `}
  }
`

const Main = styled.main`
  position: relative;

  &.sidebar-padding {
    padding-top: 72px;

    @media (min-width: 768px) {
      padding-top: 160px;
    }
  }
`

export default Layout
