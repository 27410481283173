import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { GET_PRODUCT_BY_SLUG } from "shared-queries"
import styled from "styled-components"
import { ProductLayout } from "./ProductLayout"
import withLocation from "../utils/withLocation"
import { Sidebar } from "./Sidebar"
import { useEffect } from "react"
import { Link } from "gatsby"
import LazyImage from "./LazyImage"
import Scroll from "react-scroll"
import { getLinkTarget } from "../utils/helpers"

const ProductFlyout: React.FC = (props) => {
  const { search, blok } = props

  const [currentPost, setCurrentPost] = useState(null)
  const [nextPost, setNextPost] = useState(null)
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)

  const queryParam = blok?.query_param ? blok?.query_param : "product"
  const { error, data } = useQuery(GET_PRODUCT_BY_SLUG, {
    variables: {
      mainSlug: `products/${search[queryParam]}`,
      secondarySlug: `products/${search[queryParam]}`,
    },
    onCompleted: () => {
      if (error) return null
      setCurrentPost({
        slug: data?.ProductItem?.full_slug,
        ...data?.ProductItem?.content,
      })
      if (
        data?.ProductItems &&
        Array.isArray(data?.ProductItems?.items) &&
        data?.ProductItems?.items.length > 0
      ) {
        const nextProductItem = data?.ProductItems?.items.find(
          (productItem) => {
            return productItem.id > data?.ProductItem?.id
          }
        )

        // If there's no next post available we circle back to first element
        if (!nextProductItem) {
          setNextPost({
            slug: `products/${data?.ProductItems?.items[0]?.slug}`,
            ...data?.ProductItems?.items[0]?.content,
          })
        } else {
          setNextPost({
            slug: `products/${nextProductItem?.slug}`,
            ...nextProductItem?.content,
          })
        }
      }
    },
    skip:
      typeof search === "undefined" ||
      typeof search[queryParam] === "undefined",
    ssr: false,
  })

  useEffect(() => {
    if (!search[queryParam]) {
      setCurrentPost(null)
    }
  }, [search])

  useEffect(() => {
    if (currentPost !== null) {
      typeof document !== "undefined" &&
        document.body.classList.add("flyout-open")
      setIsFlyoutOpen(true)
      Scroll.animateScroll.scrollToTop({
        duration: 100,
      })
      setTimeout(
        () => {
          setIsFlyoutVisible(true)
        },
        isFlyoutOpen ? 2000 : 300
      )
    } else {
      typeof document !== "undefined" &&
        document.body.classList.remove("flyout-open")
      setIsFlyoutOpen(false)
      setIsFlyoutVisible(false)
    }
  }, [currentPost])

  return (
    <ProductFlyoutWrapper
      className="bg-white"
      isActive={isFlyoutOpen}
      isVisible={isFlyoutVisible}
    >
      {currentPost ? (
        <ProductColumns className="product-layout__grid md:grid">
          <ProductMainColumn className="product-layout__main">
            <ProductLayout blok={currentPost} enableBackAction={true} />
          </ProductMainColumn>
          <ProductSidebarColumn className="product-layout__sidebar bg-gray-light">
            <Sidebar mode={"flyout"} excludedPost={currentPost.slug} />
          </ProductSidebarColumn>
          {nextPost && (
            <ProductNextRow className="product-layout__next">
              <Link
                className={`product-layout__nextp--link`}
                to={
                  currentPost.next_link && currentPost.next_link?.cached_url
                    ? getLinkTarget(currentPost.next_link)
                    : nextPost?.slug
                }
              >
                <ProductNextRowCol className={"product-layout__label"}>
                  <ProductNextRowLabel>Next</ProductNextRowLabel>
                </ProductNextRowCol>
                <ProductNextRowCol className={"product-layout__cta"}>
                  <ProductNextRowLabel
                    className={`product-layout__nextp--title`}
                  >
                    {currentPost?.next_title
                      ? currentPost?.next_title
                      : nextPost?.name}
                  </ProductNextRowLabel>
                </ProductNextRowCol>
                <ProductNextRowCol className={"product-layout__media"}>
                  {currentPost?.next_image?.filename ? (
                    <LazyImage
                      className={`product-layout__nextp--image`}
                      src={currentPost?.next_image?.filename}
                      alt={
                        currentPost?.next_image?.alt
                          ? currentPost?.next_image?.alt
                          : ""
                      }
                      resize={"320x156"}
                    />
                  ) : nextPost?.preview_image?.filename ? (
                    <LazyImage
                      className={`product-layout__nextp--image`}
                      src={nextPost?.preview_image?.filename}
                      alt={
                        nextPost?.preview_image?.alt
                          ? nextPost?.preview_image?.alt
                          : nextPost?.name
                      }
                      resize={"320x156"}
                    />
                  ) : (
                    <LazyImage
                      className={`product-layout__nextp--image`}
                      src={nextPost?.main_image?.filename}
                      alt={nextPost?.name}
                      resize={"320x156"}
                    />
                  )}
                </ProductNextRowCol>
              </Link>
            </ProductNextRow>
          )}
        </ProductColumns>
      ) : null}
    </ProductFlyoutWrapper>
  )
}

const ProductFlyoutWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
  background-color: #ffffff;
  //left: ${(props) => (props.isActive ? "0" : "-100vw")};
  z-index: 99;
  opacity: 0;
  transition: opacity 100ms;
  ${(props) =>
    props.isActive
      ? `
        transition: opacity 800ms 500ms;
    `
      : `
        display: none;
        position: absolute;
    `}
  ${(props) =>
    props.isVisible
      ? `
        opacity: 1;
    `
      : null}
`

const ProductNextRow = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  > a {
    display: flex;
    flex-direction: column;
    background-color: #fec430;
    padding: 20px 8px;
    height: max-content;
    margin-top: auto;
    grid-column: 1 / -1;
    box-shadow: none;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 22px 24px;
    }
  }

  &:hover {
    img {
      transform: scale(1.03);
    }
  }
`

const ProductNextRowCol = styled.div`
  width: auto;
  .product-layout__nextp--title {
    font-family: "Favorit Pro Light";
    font-size: 2.625rem;
    line-height: 3.125rem;
    box-shadow: none;

    @media (min-width: 768px) {
      font-size: 4rem;
      line-height: 4.25rem;
      margin: 0px;
    }
  }
  .product-layout__nextp--image {
    position: relative;
    width: 320px;
    height: 156px;
    margin-left: auto;
    overflow: hidden;

    img {
      transition: all 0.1s ease-in-out;
    }
  }

  &.product-layout__label {
    font-family: "Favorit Pro Light";
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    max-width: auto;

    @media (min-width: 768px) {
      max-width: 230px;
    }
  }

  &.product-layout__cta {
    margin-top: 12px;
    margin-bottom: 18px;

    @media (min-width: 768px) {
      flex-grow: 1;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  @media (min-width: 768px) {
    width: 33.33%;
  }
`

const ProductNextRowLabel = styled.div`
  @media (min-width: 768px) {
    margin-top: calc(4.25rem - 30px);
  }
`

const ProductColumns = styled.div`
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  overflow: auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 218px;
    overflow: visible;
  }
`

const ProductMainColumn = styled.div`
  padding: 100px 0px;
`

const ProductSidebarColumn = styled.div``

export default withLocation(ProductFlyout)
