import { gql } from "@apollo/client"

export const GET_FEATURED_PRODUCTS = gql`
  query featuredProducts($slug: String!) {
    ProductItems(excluding_slugs: $slug, per_page: 4, page: 1) {
      items {
        id
        name
        slug
        full_slug
        content {
          sku
          name
          main_image {
            title
            name
            id
            focus
            filename
            copyright
            alt
          }
          description
          component
          body
          preview_excerpt
          preview_image {
            title
            name
            id
            focus
            filename
            copyright
            alt
          }
        }
      }
    }
  }
`

export const GET_PRODUCT_BY_SLUG = gql`
  query getProductByUrl($mainSlug: ID!, $secondarySlug: String!) {
    ProductItem(id: $mainSlug) {
      id
      slug
      full_slug
      content {
        body
        component
        main_image {
          alt
          copyright
          filename
          id
          name
          title
        }
        name
        sku
        description
        next_title
        next_link {
          linktype
          cached_url: cachedUrl
        }
        next_image {
          title
          name
          id
          focus
          filename
          copyright
          alt
        }
      }
    }
    ProductItems(
      excluding_slugs: $secondarySlug
      per_page: 50
      page: 1
      sort_by: "id"
    ) {
      items {
        id
        name
        slug
        full_slug
        content {
          name
          main_image: preview_image {
            title
            name
            id
            focus
            filename
            copyright
            alt
          }
        }
      }
    }
  }
`
